import Layout from '../components/Layout';
import styles from '../styles/404.module.scss';

export default function Custom404() {
  return (
    <Layout>
      <div className={styles.main}>
        <div className={styles.notfound}>
          <div className={styles.notfound404}>
            <h1>Oops!</h1>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <h2>404 - The Page can't be found</h2>
          </div>
          <a href="/">Go TO Homepage</a>
        </div>
      </div>
    </Layout>
  );
}
